<template>
  <div>
    <my-header
      :wilayas="getOrganisationWilaysNames"
      :profile_img_url="organisationLogo"
      :region="getRegion"
    ></my-header>

    <my-counters
      :nbre_accepter="acceptes"
      :nbre_refuser="refuser"
      :nbre_an_attente="en_attente"
    >
    </my-counters>
    <!-- <bar-chart> </bar-chart>  -->
    <a-spin :spinning="loading" :delay="delayTime">
      <a-row type="flex" justify="space-around">
        <a-col xs="{ span: 24  }" :md="{ span: 11 }">
          <a-card title="Statistique par sexe" :bordered="true">
            <a-row type="flex" justify="space-around">
              <a-col
                :xs="selectLayoutXs"
                :md="{ span: 7 }"
                style="margin-right: 2%"
              >
                <a-select
                  placeholder="Etat inscription"
                  @change="handleEtatFilterChange"
                  class="fill_space"
                >
                  <!--                                    <a-select-option value="-">Non Soumis</a-select-option>-->
                  <a-select-option value="">Tous</a-select-option>
                  <a-select-option
                    v-for="etatAdherents in etatsAdherents"
                    :key="etatAdherents.key"
                    >{{ etatAdherents.value }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col
                :xs="selectLayoutXs"
                :md="{ span: 7 }"
                style="margin-right: 2%"
              >
                <a-select
                  placeholder="Sexe"
                  class="fill_space"
                  @change="handleSexeFilterChange"
                >
                  <a-select-option value="">Tous</a-select-option>
                  <a-select-option value="0">Homme</a-select-option>
                  <a-select-option value="1">Femme</a-select-option>
                </a-select>
              </a-col>
              <!--    End Sexe -->
              <!--                Start  Wilaya-->

              <a-col :xs="selectLayoutXs" :md="{ span: 7 }">
                <a-select
                  placeholder="Wilaya"
                  class="fill_space"
                  @change="handleWilayaFilterChange"
                >
                  <a-select-option value="">Tous</a-select-option>
                  <a-select-option
                    v-for="wilaya in organisation_wilayas"
                    :key="wilaya.id"
                    >{{ wilaya.nom_fr }}
                  </a-select-option>
                </a-select>
              </a-col>
              <!--                End Wilaya -->
            </a-row>
            <a-row type="flex" justify="center" align="middle">
              <!--        <a-col :xs="{ span: 24  }" :lg="{ span: 11  }">-->
              <!--          <pie-chart  :chartData="chartdata" ></pie-chart>-->
              <!--        </a-col>-->
              <a-col>
                <bar-chart :chartData="chartData"></bar-chart>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
        <a-col xs="{ span: 24  }" :md="{ span: 11 }">
          <a-card title="Statistique par paiement" :bordered="true">
            <a-row
              style="margin-bottom: 0%;"
              type="flex"
              justify="space-around"
            >
              <!--                Start  Wilaya-->
              <a-col
                :xs="selectLayoutXs"
                :md="{ span: 10 }"
                style="margin-right : 5%"
              >
                <a-select
                  placeholder="Wilaya"
                  class="fill_space"
                  @change="handlePaiementWilayaFilterChange"
                >
                  <a-select-option value="">Tous</a-select-option>
                  <a-select-option
                    v-for="wilaya in organisation_wilayas"
                    :key="wilaya.id"
                    >{{ wilaya.nom_fr }}
                  </a-select-option>
                </a-select>
              </a-col>
              <!--  End Wilaya -->
              <!--                Start  Catégorie-->
              <a-col :xs="selectLayoutXs" :md="{ span: 10 }">
                <a-select
                  placeholder="Secteur"
                  class="fill_space"
                  @change="handleSecteurActiviteFilterChange"
                >
                  <a-select-option value="">Tous</a-select-option>
                  <a-select-option v-for="secteur in secteurs" :key="secteur.id"
                    >{{ secteur.nom_fr }}
                  </a-select-option>
                </a-select>
              </a-col>
              <!--  End Catégorie -->
            </a-row>
            <a-row
              type="flex"
              justify="center"
              align="middle"
              style="margin-top: 0px"
            >
              <a-col>
                <pie-chart :chartData="paiementCharData"></pie-chart>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
  import BarChart from "./BarChart";
  import PieChart from "./PieChart";
  import MyHeader from "@/components/common/Header";
  import MyCounters from "./Counters";
  import {mapActions, mapGetters, mapState} from "vuex";
  import _forEach from "lodash-es/forEach";
  import moment from "moment";

  const selectLayoutLg = {
    span: 4
  };
  const selectLayoutXs = {
    span: 24
  };
  export default {
    name: "DashboardIndex",
    components: {MyHeader, BarChart, PieChart, MyCounters},
  created() { 
    this.getAdditioanlData();
    this.fetchData();
    this.fetchData("paiement");
    this.fetchCounters();
  },
  data() {
    return {
      loading: false,
      delayTime: 1000,
      newEntriesTimer: 20000,
      newEntriesTimeout: null,
      filter_by: "sexe",
      selected_excercice_value: "",
      selected_etat_value: "",
      selected_paiement_value: "",
      selected_wilaya_paiement__value: "",
      selected_sexe_value: "",
      selected_wilaya_value: "",
      selected_secteur_activite_value: "",
      selectLayoutLg,
      selectLayoutXs
    };
  },
  computed: {
    ...mapState({
      sor: state => state.organisation.organisation,
      exercices: state => state.exercices.exercices,
      current_exercice: state => state.exercices.current_exercice,
      etatsAdherents: state => state.adherents.etatsAdherents,
      organisation_wilayas: state => state.wilayas.organisation_wilayas,
      data: state => state.statistiques.stats.wilaya_stats,
      labels: state => state.statistiques.stats.labels,
      paiement_stats: state => state.statistiques.stats.paiement_stats,
      state: state => state.statistiques,
      paiement_labels: state => state.statistiques.stats.paiement_labels,
      secteurs: state => state.secteurs.secteurs,
      refuser: state => state.statistiques.counters.refuser,
      en_attente: state => state.statistiques.counters.en_attente,
      acceptes: state => state.statistiques.counters.acceptes
    }),
    ...mapGetters({
      getOrganisationWilaysNames: "getOrganisationWilaysNames",
      getRegion: "getRegion",
      organisationLogo:"getOrganisationLogo"
    }),
    statsHommes() {
      let a = [];
      _forEach(this.state.wilayas, function(value, key) {
        a.push(value["hommes"]);
      });
      return a;
    },
    statsFemmes() {
      let a = [];
      _forEach(this.state.wilayas, function(value, key) {
        a.push(value["femmes"]);
      });
      return a;
    },
    chartData() {
      return {
        labels: this.labels,
        datasets: [
          {
            label: "# Homme",
            data: this.statsHommes,
            backgroundColor: "#41b883",
            borderColor: "#41b883",
            borderWidth: 1
          },
          {
            label: "# Femme",
            data: this.statsFemmes,
            backgroundColor: "#35495e",
            borderColor: "#35495e",
            borderWidth: 1
          }
        ]
      };
    },
    paiementCharData() {
      return {
        labels: this.paiement_labels,
        datasets: [
          {
            label: "Paiment",
            data: this.paiement_stats,
            backgroundColor: ["rgba(35,255,24,0.2)", "rgba(54, 162, 235, 1)"],
            borderColor: ["rgba(92,255,143,0.2)", "rgba(54, 162, 235, 1)"],
            borderWidth: 1
          }
        ]
      };
    }
  },
  watch: {
    selected_etat_value: function(oldVal, newVal) {
      this.fetchData();
    },
    selected_sexe_value: function(oldVal, newVal) {
      this.fetchData();
    },
    selected_wilaya_value: function(oldVal, newVal) {
      this.fetchData();
    },
    selected_excercice_value: function(oldVal, newVal) {
      this.fetchData();
    },
    selected_wilaya_paiement__value: function(oldVal, newVal) {
      this.fetchData("paiement");
    },
    selected_secteur_activite_value: function(oldVal, newVal) {
      this.fetchData("paiement");
    },
    filter_by: function(oldVal, newVal) {
      this.fetchData();
    }
  },
  methods: {
    moment,
    fetchData(filter_by = "sexe") {
      this.fetchStats({filter_by: filter_by, ...this.getFilters()});
    },
    getAdditioanlData() {
      this.$store.cache.dispatch("fetchOrganisation");
      this.$store.cache.dispatch("fetchExercices");
      this.$store.cache.dispatch("fetchSecteurs");
      this.$store.cache.dispatch("fetchOrganisationWilayas");
    },
    handleEtatFilterChange(selectedEtat) {
      this.selected_etat_value = selectedEtat;
    },
    handlePaiementFilterChange(selectedItem) {
      this.selected_paiement_value = selectedItem;
    },
    handleSexeFilterChange(selectedItem) {
      this.selected_sexe_value = selectedItem;
    },
    handleWilayaFilterChange(selectedItem) {
      this.selected_wilaya_value = selectedItem;
    },
    handlePaiementWilayaFilterChange(selectedItem) {
      this.selected_wilaya_paiement__value = selectedItem;
    },
    handleSecteurActiviteFilterChange(selectedItem) {
      this.selected_secteur_activite_value = selectedItem;
    },
    getFilters() {
      return {
        'sexe': this.selected_sexe_value,
        'etat': this.selected_etat_value,
        'wilaya': this.selected_wilaya_value,
        'wilaya_paiement': this.selected_wilaya_paiement__value,
        'secteur': this.selected_secteur_activite_value,
      };
    },
    checkForNewEntries() {
      this.newEntriesTimeout = setInterval(() => {
        this.fetchData("paiement");
        this.fetchData();
        this.fetchCounters();
      }, this.newEntriesTimer);
    },
    ...mapActions({
      fetchStats: "fetchStats",
      fetchCounters: "fetchCounters"
    })
  }
};
</script>
<style>
.fill_space {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: stretch;
  margin-bottom: 10px;
}
.ant-card-body {
  padding: 0%;
  padding-top: 2%;
}
</style>
