<template>
    <div>
        <a-row type="flex" justify="space-around" align="middle" class="margin">
            <a-card
                    hoverable
                    :bodyStyle="{ 'padding' : '0px'}"
                    style="width: 300px; margin-bottom: 0px;    padding-bottom: 0px;"
            >
                <a-row type="flex" justify="center" align="middle" >
                    <a-col :md="{ span: 14  }">
                        <a-row type="flex" justify="center" align="top">
                            <a-col :md="{ span: 24  }" style="margin-bottom: 10px">
                                <a-icon type="check-circle" style="color: #00c292; font-size: 40px; font-weight: 400;"/>
                            </a-col>
                            <a-col :md="{ span: 20  }">
                               <span style="font-size: 18px; color:#8d9ea7;margin-bottom: 0px;">Acceptés</span>
                            </a-col>
                        </a-row>

                    </a-col>
                    <a-col :md="{ span: 8 }">
                        <p style="color: #00c292;text-align: center;font-size: 48px;font-weight: 100;">{{nbre_accepter}}</p>
                    </a-col>
                </a-row>
            </a-card>
            <a-card
                    hoverable
                    :bodyStyle="{ 'padding' : '0px'}"
                    style="width: 300px; margin-bottom: 0px;    padding-bottom: 0px;"
            >
                <a-row type="flex" justify="center" align="middle" >
                    <a-col :md="{ span: 14  }">
                        <a-row type="flex" justify="center" align="top">
                            <a-col :md="{ span: 24  }" style="margin-bottom: 10px">
                                <a-icon type="pause-circle" style="color: #01c0c8; font-size: 40px; font-weight: 400;"/>
                            </a-col>
                            <a-col :md="{ span: 20  }">
                               <span style=" font-size: 18px; color:#8d9ea7;margin-bottom: 0px;">En attente</span>
                            </a-col>
                        </a-row>

                    </a-col>
                    <a-col :md="{ span: 8 }">
                        <p style="color: #01c0c8;text-align: center;font-size: 48px;font-weight: 100;">{{nbre_an_attente}}</p>
                    </a-col>
                </a-row>
            </a-card>
            <a-card
                    hoverable
                    :bodyStyle="{ 'padding' : '0px'}"
                    style="width: 300px; margin-bottom: 0px;    padding-bottom: 0px;"
            >
                <a-row type="flex" justify="center" align="middle" >
                    <a-col :md="{ span: 14  }">
                        <a-row type="flex" justify="center" align="top">
                            <a-col :md="{ span: 24  }" style="margin-bottom: 10px">
                                <a-icon type="close-circle" style="font-size: 40px; color: #fb9678;font-weight: 400;"/>
                            </a-col>
                            <a-col :md="{ span: 20  }">
                               <span style="font-size: 18px; color:#8d9ea7;margin-bottom: 0px;">Refusé </span>
                            </a-col>
                        </a-row>

                    </a-col>
                    <a-col :md="{ span: 8 }">
                        <p style="color: #fb9678;text-align: center;font-size: 48px;font-weight: 100;">{{nbre_refuser}}</p>
                    </a-col>
                </a-row>
            </a-card>
        </a-row>
    </div>
</template>

<script>
    import ACol from "ant-design-vue/es/grid/Col";

    export default {
        name: "Counters",
        components: {ACol},
        props: ['nbre_accepter', 'nbre_refuser', 'nbre_an_attente']
    }
</script>

<style scoped>
    .margin {
        margin-bottom: 5%;
        margin-top: 3%;
    }
    .ant-card-body{
        padding-bottom: 0px;
    }
</style>