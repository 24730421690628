 <script>
     import {mixins, Pie} from "vue-chartjs";

     const {reactiveProp} = mixins;
     export default {
         extends: Pie,
         mixins: [reactiveProp],
         data: () => ({

             options: {
                 responsive: true,
                 maintainAspectRatio: false
             }
         }),
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
</script>
 